import React, { cloneElement, Fragment, useState, useEffect } from 'react';

import useRouter from './hooks/useRouter';

import useRouteStore from './stores/useRouteStore';

export default function ToolContainer({ children, submenu = false }) {

    const currentCommunity = useRouteStore(state => state.currentCommunity);
    const currentTool = useRouteStore(state => state.currentTool);
    const { changeRoute } = useRouter();

    const [items, _items] = useState([]);

    useEffect(() => {
        if (!currentCommunity || !currentTool) return;

        let nItems = [];
        if(!submenu) {
            let insertedGroups = [];

            currentCommunity.tools.forEach(t => {
                if(!t.group) nItems.push({ type: 'tool', tool: t, active: currentTool.id === t.id}); // insert tool
                else if(!insertedGroups.includes(t.group)) {
                    insertedGroups.push(t.group);
                    let group = currentCommunity.groups.find(g => g.id === t.group); // Does the group exist?
                    if(group) nItems.push({ type: 'group', group, active: !!currentCommunity.tools.find(to => currentTool.id === to.id && to.group === t.group) }); // insert group (if exists)
                    else nItems.push({ type: 'tool', tool: t, active: currentTool.id === t.id}); // insert tool (if doesnt exist)
                }
            });            
        } else if(!!currentTool.group) nItems = currentCommunity.tools.filter(t => t.group === currentTool.group).map(t => ({ type: 'tool', tool: t, active: currentTool.id === t.id }));


        let preparedItems = nItems.map(i => {
            if(i.type==='tool') return {    
                id: i.tool.id, 
                icon: i.tool.icon, 
                title: i.tool.title, 
                active: i.active,
                onClick: handleToolChange, 
            } 
            else return {  
                id: i.group.id, 
                icon: i.group.icon, 
                title: i.group.title, 
                active: i.active,
                onClick: handleGroupChange 
            };
        });
        _items(preparedItems);

    }, [currentCommunity, submenu, currentTool])

    const handleToolChange = (toolId) => {

        const tool = currentCommunity.tools.find(({ id }) => id === toolId);

        changeRoute({
            tool: tool.alias,
        })
    }
    const handleGroupChange = (groupId) => {

        const tool = currentCommunity.tools.find(({ group }) => group === groupId);

        changeRoute({
            tool: tool.alias,
        })
    }

    if (!currentCommunity || !currentTool) return null;

    const ToolMenu = cloneElement(children, {
        items,
    })


    return (<>
        {ToolMenu}
    </>)
}