import React, { Fragment } from "react";

// import { useUser } from '../Central_REMOVER';

export default function ToolCore1() {
    /* const { updateUser } = useUser(); */

    return (<Fragment>
        <h5>[CORE TOOL 1]</h5>
        {/* <button onClick={()=>updateUser()}>Atualiza dados do user</button> */}
    </Fragment>)
}