import React, { useState, useEffect, useContext, useReducer, cloneElement, Fragment } from 'react';

import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import DorothyConfig from './context/DorothyConfigContext';
import EventContext from './context/EventContext';

import coreTools from './tools';

import useMessagery from './hooks/useMessagery';

import useUserStore from './stores/useUserStore';
import useRouteStore from './stores/useRouteStore';

export default function Flow({ isCommunity = false, item, children, customMessageNotification = true }) {
    /* Para fluxos flutuantes, isCommunity(?) e isTool(?) poderiam receber os ids de comunidade e tool, respectivamente  */
    const user = useUserStore(state => state.user);
    const currentCommunity = useRouteStore(state => state.currentCommunity)
    const currentTool = useRouteStore(state => state.currentTool)

    const { config } = useContext(DorothyConfig);
    const ee = useContext(EventContext);

    const [notifications, dispatch] = useReducer(reducer, [])
    const { onLeave, onEnter, onSend } = useMessagery();

    const [roomName, _roomName] = useState(null);

    const [mRef, _mRef] = useState(null);
    const [hasMore, _hasMore] = useState(null);

    const [loading, _loading] = useState(false);
    const [sending, _sending] = useState(false);

    useEffect(() => {
        let roomNameSegments = ['room'];
        if (isCommunity) roomNameSegments.push(`c${currentCommunity.id}`);
        roomNameSegments.push(`t${currentTool.id}`);
        if (item && item.length) roomNameSegments.push(`i${item}`);
        const eRoom = roomNameSegments.join('_');

        _roomName(eRoom);

        onEnter(eRoom);
        return () => onLeave(eRoom);
    }, [])

    useEffect(() => {
        if (!roomName) return;

        function dispatchNotification(notification) {
            dispatch({ type: 'add', notification, tools: config.tools });
        }
        function updateNotification(notification) {
            dispatch({ type: 'update', notification, tools: config.tools });
        }
        function refreshNotification(id) {
            dispatch({ type: 'refresh', id });
        }

        ee.on(`message_to_${roomName}`, dispatchNotification);
        ee.on(`update_to_${roomName}`, updateNotification);
        ee.on(`refresh_to_${roomName}`, refreshNotification);

        return () => {
            ee.off(`message_to_${roomName}`, dispatchNotification);
            ee.off(`update_to_${roomName}`, updateNotification);
            ee.off(`refresh_to_${roomName}`, refreshNotification);
        }
    }, [roomName])

    useEffect(() => {
        async function fetchMessages() {
            _loading('fetching');

            const {
                data: {
                    notifications,
                    hasMore,
                    messageRef,
                }
            } = await axios.get(`${config.server}messagery/messages/${roomName}`);

            _hasMore(hasMore);
            _mRef(messageRef);
            dispatch({ type: 'load', notifications, tools: config.tools });

            _loading('fetched');
        }

        if (roomName) fetchMessages();

    }, [roomName])

    const doSend = async (message) => {
        _sending('sending');

        /* optimistic */
        const optimistic_id = uuidv4();
        let notification = {
            createdAt: new Date(),
            id: optimistic_id,
            user_id: user.id,
            user_name: user.name,
            tool: {
                type: customMessageNotification ? "native" : "core",
                element: "MessageNotification",
            },
            content: {
                "text": message,
            },
            optimistic: true,
        }
        dispatch({ type: 'add', notification, tools: config.tools });

        /* SEND */
        onSend(roomName, notification, (confirmedNotification) => {
            dispatch({ type: 'confirm', optimistic_id, confirmedNotification, tools: config.tools });
            _sending(null);
        })
    }

    const onMore = async () => {
        _loading('fetching_more');

        const {
            data: {
                notifications,
                hasMore,
                messageRef,
            }
        } = await axios.get(`${config.server}messagery/messages/${roomName}/?threshold=${mRef}`);

        _hasMore(hasMore);
        _mRef(messageRef);
        dispatch({ type: 'more', notifications, tools: config.tools });

        _loading('fetched');
    }

    const FlowRenderer = cloneElement(children, {
        notifications,
        hasMore,
        onSend: doSend,
        onMore,
        loading,
        sending,
    })

    return (<>
        {FlowRenderer}
    </>)
}


function reducer(state, action) {

    function mapElement(notifications, nativeTools) {
        let single = false;
        if (!Array.isArray(notifications)) {
            notifications = [notifications];
            single = true;
        }

        let prepared = notifications.map(n => {
            const { type, element } = n.tool; /* TODO: e se nao tiver - erro*/

            let Element;
            if (type === 'core' && coreTools[element]) Element = coreTools[element];
            else if (type === 'native' && nativeTools[element]) Element = nativeTools[element];
            else Element = UnknownNotificationTool;

            /* TODO: Consigo passar os dados para o Elemento, aqui? */

            // console.log(n)
            return { ...n, Element, updated: Date.now() };
        })

        return single ? prepared[0] : prepared;
    }

    let nState, newN;
    switch (action.type) {
        case 'more':
            newN = mapElement(action.notifications, action.tools)
            return [...state, ...newN];
        case 'load':
            return mapElement(action.notifications, action.tools);
        case 'add':
            return [mapElement(action.notification, action.tools), ...state];
        case 'refresh':
            nState = state.map(n => {
                // altera a data de atualizacao
                if (n.id === action.id) return { ...n, updated: Date.now() };
                return n;
            });
            return nState;

        case 'update':
            nState = state.map(n => {
                // subistitui a notificacao atualizada
                if (n.id === action.notification.id) return mapElement(action.notification, action.tools);
                return n;
            });
            return nState;
        case 'confirm':
            nState = state.map(n => {
                // subistitui a notificacao atualizada
                if (n.id === action.optimistic_id) return mapElement(action.confirmedNotification, action.tools);
                return n;
            });
            return nState;
        default:
            throw new Error();
    }
}

function UnknownNotificationTool() {
    return (<>UnknownNotificationTool</>);
}