import React, { useState, useEffect, Fragment } from 'react';

import axios from 'axios';

import io from 'socket.io-client';
import EventEmitter from 'events';

import {
    BrowserRouter as Router,
} from "react-router-dom";

import useUser from './hooks/useUser';

import useDorothyStore from './stores/useDorothyStore';
import useUserStore from './stores/useUserStore';
import useRouteStore from './stores/useRouteStore';

import DorothyConfig from './context/DorothyConfigContext';
import SocketContext from './context/SocketContext';
import EventContext from './context/EventContext';

let socket, ee;
export default function Dorothy({ config, children, preparingEl = <RHElement /> }) {
    const rehidrate = useUserStore(state => state.rehidrate);
    const login = useUserStore(state => state.login)

    const cacheCommunity = useRouteStore(state => state.cacheCommunity)

    const activeRooms = useUserStore(state => state.activeRooms)

    const setMessageryStatus = useDorothyStore(state => state.setMessageryStatus);

    const setAlertCount = useUserStore(state => state.setAlertCount);
    const incAlertCount = useUserStore(state => state.incAlertCount);
    const decAlertCount = useUserStore(state => state.decAlertCount);

    const addAlert = useUserStore(state => state.addAlert);
    const removeAlert = useUserStore(state => state.removeAlert);
    const alertsLoaded = useUserStore(state => state.alertsLoaded);

    const { updateUser } = useUser();

    const [initiated, _initiated] = useState(false);

    useEffect(() => {
        async function doRehidrate() {

            rehidrate(true);

            axios.defaults.headers.common['X-Dorothy-Token'] = localStorage.getItem(`${config.app_name}-dorothy-token`);
            if (socket) socket.emit('token', localStorage.getItem(`${config.app_name}-dorothy-token`));
            const { data } = await axios.get(`${config.server}user/me`);

            cacheCommunity(data.user.membership);

            setAlertCount(data.user.alertCounter);

            login(data);
            rehidrate(false);
            _initiated(true);
        }

        /* MELHORAR */
        if (localStorage.getItem(`${config.app_name}-dorothy-token`)) doRehidrate();
        else _initiated(true);
    }, []);

    useEffect(() => {

        /* Messagery */
        socket = io(config.server);
        ee = new EventEmitter();

        socket.onAny((event, ...args) => {
            console.log(`got ${event}`, args);
        });
        socket.on('connect', () => {
            setMessageryStatus(true);
            socket.emit('token', localStorage.getItem(`${config.app_name}-dorothy-token`));
        })
        socket.on('notification', (...args) => {
            const [room, notification] = args;

            if (!room) return;

            ee.emit(`message_to_${room}`, notification);
        })
        socket.on('update', (...args) => {
            const [room, notification] = args;

            if (!room) return;

            ee.emit(`update_to_${room}`, notification);
        })
        socket.on('refresh', (...args) => {
            const [room, { id }] = args;

            if (!room) return;

            ee.emit(`refresh_to_${room}`, id);
        })
        socket.on('reconnect_error', () => console.log('SOCKET RECONNECTION ERROR!!!'));
        socket.on("disconnect", (reason) => {
            console.log(`Disconnection! (reason: ${reason})`);
            setMessageryStatus(false);
        })

        return () => {
            socket.offAny();
            socket.disconnect();
        }
    }, []);

    useEffect(() => {
        socket.io.on('reconnect', () => {
            socket.emit('remember', activeRooms);
        });

        return () => socket.io.off('reconnect');
    }, [activeRooms])

    useEffect(() => {
        socket.on('command', (...args) => {
            const [command, payload] = args;

            switch (command) {
                case 'update_user':
                    updateUser();
                    break;
                case 'new_alert':
                    incAlertCount();
                    console.log('>>', alertsLoaded, payload.alert);
                    if (alertsLoaded) addAlert(payload.alert);
                    break;
                case 'reset_alert':
                    decAlertCount(payload.alerts.length);
                    if (alertsLoaded) payload.alerts.forEach(alert => removeAlert(alert));
                    break;
                case 'remove_alert':
                    decAlertCount(payload.alerts.length);
                    if (alertsLoaded) payload.alerts.forEach(alert => removeAlert(alert));
                    break;
                default:
                    console.error(`command "${command}" is unknown!`);
            }
        })

        return () => socket.off('command');
    }, [alertsLoaded, config]);

    return (<Router>
        <DorothyConfig.Provider value={{ config, initiated }}>
            <SocketContext.Provider value={socket}>
                <EventContext.Provider value={ee}>
                    {initiated && <Fragment>{children}</Fragment>}
                    {!initiated && preparingEl && <Fragment>{preparingEl}</Fragment>}
                </EventContext.Provider>
            </SocketContext.Provider>
        </DorothyConfig.Provider>
    </Router>)
}

/* Support Components and functions*/

function RHElement() {
    return (<>Preparando...</>);
}