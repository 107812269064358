import React, { useState, useEffect, Fragment } from 'react';

import dayjs from 'dayjs';

export default function MessageNotification({ data }) {
    const [date, _date] = useState('');
    const [sender, _sender] = useState('');
    const [text, _text] = useState('');
    const [optimistic, _optimistic] = useState('');

    useEffect(()=>{
        if(!data) return;

        _date(dayjs(data.createdAt).format('DD/MM/YYYY HH:mm'));
        _sender(data.user_name);
        _text(data.content.text);
        _optimistic(data.optimistic)
    },[data]);
    
    return (<div>{date} <strong>{sender}</strong> enviou uma mensagem: {text} {optimistic && <>*</>}</div>)
}