import { snakeCase } from 'snake-case';

export function transformCommunityData(community) {

    /* traz as configuracoes da comunidade para a superficie */
    const nCommunity = { ...community, ...community.descriptor_json };
    delete nCommunity.descriptor_json;

    /* cria alias para cada ferramenta sem alias */
    nCommunity['tools'].forEach(t => { if (!t.alias) t.alias = snakeCase(t.title) })

    return nCommunity;
}