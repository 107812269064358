import { useContext } from 'react';

import DorothyConfig from '../context/DorothyConfigContext';

import useDorothyStore from '../stores/useDorothyStore';

export default function useDorothy() {
    const context = useContext(DorothyConfig);

    const isMessageryConnected = useDorothyStore(state => state.isMessageryConnected);

    return { ...context.config, isMessageryConnected };
}