import { useContext } from 'react';

import axios from 'axios';

import DorothyConfig from '../context/DorothyConfigContext';

import useRouteStore from '../stores/useRouteStore';

import { transformCommunityData } from '../utils';

import {
    useHistory,
} from "react-router-dom";

export default function useRouter() {
    const history = useHistory();

    const currentCommunity = useRouteStore(state => state.currentCommunity)
    const currentTool = useRouteStore(state => state.currentTool)
    const cachedCommunities = useRouteStore(state => state.communities)
    const cacheCommunity = useRouteStore(state => state.cacheCommunity)

    const params = useRouteStore(state => state.params);

    const rounting = useRouteStore(state => state.rounting)
    const setRouting = useRouteStore(state => state.setRouting)

    const { config } = useContext(DorothyConfig);

    async function getCommunityData(communityId) {
        /* try to retrieve community from cache */
        let communityData = cachedCommunities.find(({ id }) => id === communityId);

        /* not found? retrieve current community data from DB */
        if (!communityData) {
            const { data: community } = await axios.get(`${config.server}user/me/community/${communityId}/membership`);

            if (!community) throw new Error('Community not found!');

            cacheCommunity(community);
            communityData = transformCommunityData(community);
        }

        return communityData;
    }

    async function changeRoute(data) { /* Somente transforma a URL */

        let communityData;
        if (data.community) {

            setRouting(true);

            /* TODO: could forceReload be community specific? */

            /* There is no forceReload? */
            if (!data.forceReload) communityData = await getCommunityData(parseInt(data.community));

            setRouting(false);

        } else {
            communityData = currentCommunity;
            if (!data.tool) data.tool = currentTool.alias;
        }

        if (!data.tool) data.tool = communityData.tools[0].alias; /* TODO: proteger se nao houver tools para a comunidade */

        let urlParams = '';
        if (data.params) urlParams = `/${data.params.join('/')}`;

        history.push(`${config.path ? `/${config.path}` : ''}/${communityData.alias}/${communityData.id}${data.tool ? `/${data.tool}` : ''}${urlParams}`);
    }

    async function onAlertClick(data) {

        let communityData = await getCommunityData(data.c);
        let toolData = communityData.tools.find(t => t.id === parseInt(data.t));

        let preparedData = {
            community: data.c,
            tool: toolData.alias,
        }
        if (data.i) preparedData.params = [data.i, 'flow'];

        changeRoute(preparedData)
    }

    return { currentCommunity, rounting, params, changeRoute, onAlertClick, getCommunityData };
}