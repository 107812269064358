import create from 'zustand';

/* User store */

const useUserStore = create((set, get) => ({
    isLogged: false,
    rehidrating: false,
    user: null,
    token: null,
    doingLogin: false,
    activeRooms: [],
    isMessageryConnected: null,
    alertCounter: null,
    alerts: null,
    alertsLoaded: false,
    login: ({ user, token }) => set({
        isLogged: true,
        user,
        token,
    }),
    setUser: (user) => set({
        user,
    }),
    logout: () => set({
        isLogged: false,
        user: null,
        token: null,
    }),
    rehidrate: (active) => set({
        rehidrating: active,
    }),
    setDoingLogin: (doing) => set({
        doingLogin: doing,
    }),
    addRoom: (room) => {
        const rooms = get().activeRooms;
        if (rooms.includes(room)) return;
        return set({ activeRooms: [...rooms, room] });
    },
    removeRoom: (room) => {
        const rooms = get().activeRooms;
        if (!rooms.includes(room)) return;
        set({ activeRooms: rooms.filter(r => r !== room) });
    },
    setMessageryStatus: (connected) => set({
        isMessageryConnected: connected,
    }),
    setAlertCount: (alertCounter) => set({
        alertCounter,
    }),
    incAlertCount: () => set({
        alertCounter: get().alertCounter + 1,
    }),
    decAlertCount: (qtd) => set({
        alertCounter: get().alertCounter - qtd,
    }),
    markAlertsAsLoaded: () => set({
        alertsLoaded: true,
    }),
    addAlert: (alert) => set({
        alerts: [...get().alerts || [], alert],
    }),
    addAlerts: (alerts) => set({
        alerts: [...get().alerts || [], ...alerts],
    }),
    removeAlert: (alert) => set({
        alerts: get().alerts.filter(a => a.id !== alert.id),
    }),
}))

export default useUserStore;