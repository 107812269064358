import React, { useEffect, useContext, Suspense, Fragment } from 'react';

import { Route } from 'react-router-dom';

import DorothyConfig from './context/DorothyConfigContext';

import coreTools from './tools';

import useRouter from './hooks/useRouter';

import useRouteStore from './stores/useRouteStore';

export default function CommunityRouter() { /* Interpreta a URL (redireciona se nao houver comunidade ou tool no path ) */
    const { config } = useContext(DorothyConfig);

    return (<Route
        path={config.path ? `/${config.path}` : '/:alias?/:community?/:tool?/:extra*'}
        render={({ match }) => <CommunityRouterInner config={config} match={match} />}
    />)
}
function CommunityRouterInner({ config, match }) {
    const cachedCommunities = useRouteStore(state => state.communities)

    const setCurrentCommunity = useRouteStore(state => state.setCurrentCommunity);
    const setCurrentTool = useRouteStore(state => state.setCurrentTool);
    const setParams = useRouteStore(state => state.setParams);

    const currentCommunity = useRouteStore(state => state.currentCommunity);

    const rounting = useRouteStore(state => state.rounting);

    const { changeRoute, getCommunityData } = useRouter();

    /* leave community */
    useEffect(() => () => setCurrentCommunity(null), [])

    useEffect(() => {
        async function doTheRouting() {
            const communityId = match.params.community;
            const toolAlias = match.params.tool;

            const params = match.params.extra ? match.params.extra.split('/') : [];

            let communityData, toolData;

            if (communityId) communityData = await getCommunityData(parseInt(communityId));
            else {
                /* se nao tem comunidade no path, vai para a primeira */
                /* TODO: aqui, proteger se usuario nao e' membro de nenhuma comunidade */

                changeRoute({
                    community: cachedCommunities[0].id,
                    tool: cachedCommunities[0].tools[0].alias,
                });
                return null;
            }

            if (toolAlias) toolData = communityData.tools.find(({ alias }) => alias === toolAlias);
            else {
                /* se nao tem tool no path, vai para a primeira */
                /* TODO: aqui, proteger se comunidade nao tem tools */

                changeRoute({
                    community: communityData.id,
                    tool: communityData.tools[0].alias,
                });
                return null;
            }

            setParams(params);
            setCurrentCommunity(communityData);
            setCurrentTool(toolData);
        }

        doTheRouting();

    }, [match])

    return (<>

        {(!currentCommunity || rounting) && <>Carregando...</>}

        {currentCommunity && !rounting && <CurrentCommunity />}

    </>)
}

function CurrentCommunity() { /* TODO: poderia ser CurrentTool? */

    const { config } = useContext(DorothyConfig);

    const currentTool = useRouteStore(state => state.currentTool);

    /* TODO: import includes */
    //(async () => {
    //  for (const externalFile of includes) {
    //
    //      let path = '';
    //      if (!externalFile.includes('http')) path = Dorothy.defaultURL;
    //
    //      await import(/*webpackIgnore: true*/ `${path}${externalFile}`);
    //  }
    //})();

    if (!currentTool) return null; /* TODO: study */

    /* Esta parte abaixo vai no corpo mesmo? Nao seria efeito? Mas teria que colocar o ReactComponent em estado? */
    let { type = 'default' } = currentTool;

    let cType = 'webcomponent';
    let ReactComponent;
    if (['native', 'core'].includes(type)) {
        cType = 'react';
        if (type === 'native') ReactComponent = config.tools[currentTool.element] ? config.tools[currentTool.element] : UnknownTool;
        else ReactComponent = coreTools[currentTool.element] ? coreTools[currentTool.element] : UnknownTool;
    }

    /* TODO: se core, nao esta lazy */
    /* TODO: attributes, prepareAttributes e applyVars */

    if (cType === 'react') return (<Suspense fallback={<div>Carregando...</div>}>
        <ReactComponent />
    </Suspense>);

    if (cType === 'webcomponent') return (<>[CUSTOM COMPONENT]</>);

    return null;
}

/* Support Components and functions*/

function UnknownTool() {
    return (<>UnknownTool</>);
}