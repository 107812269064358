
import { useContext } from 'react';

import axios from 'axios';

import DorothyConfig from '../context/DorothyConfigContext';

import useUserStore from '../stores/useUserStore';
import useRouteStore from '../stores/useRouteStore';

import SocketContext from '../context/SocketContext';

export default function useUser() {
    const isLogged = useUserStore(state => state.isLogged);
    const doLogin = useUserStore(state => state.login)
    const doLogout = useUserStore(state => state.logout)
    const user = useUserStore(state => state.user)
    const rehidrating = useUserStore(state => state.rehidrating)
    const doing_login = useUserStore(state => state.doingLogin);
    const setDoingLogin = useUserStore(state => state.setDoingLogin);
    const alertCounter = useUserStore(state => state.alertCounter);
    const setAlertCount = useUserStore(state => state.setAlertCount);


    const alerts = useUserStore(state => state.alerts);
    const alertsLoaded = useUserStore(state => state.alertsLoaded);
    const markAlertsAsLoaded = useUserStore(state => state.markAlertsAsLoaded);
    const addAlerts = useUserStore(state => state.addAlerts);

    const cacheCommunity = useRouteStore(state => state.cacheCommunity)
    const resetCache = useRouteStore(state => state.resetCache)
    
    const setUser = useUserStore(state => state.setUser)

    const { config } = useContext(DorothyConfig);

    const socket = useContext(SocketContext);

    async function login(email, password) {

        try {
            setDoingLogin(true);
            const { data } = await axios.post(`${config.server}user/login`, {
                email,
                password,
            })

            localStorage.setItem(`${config.app_name}-dorothy-token`, data.token);
            axios.defaults.headers.common['X-Dorothy-Token'] = data.token;
            socket.emit('token', data.token);

            cacheCommunity(data.user.membership);

            setAlertCount(data.user.alertCounter);

            doLogin(data);
            setDoingLogin(false);

            return data.user;
        } catch (e) {
            console.log(e);
            setDoingLogin(false);

            return false;
        }
    }

    function logout() {
        localStorage.removeItem(`${config.app_name}-dorothy-token`);
        delete axios.defaults.headers.common['X-Dorothy-Token'];
        socket.emit('token', null);

        doLogout();
        resetCache();
    }

    async function updateUser() {
        const { data } = await axios.get(`${config.server}user/me`);

        cacheCommunity(data.user.membership);

        setUser(data.user);

    }

    async function retrieveAlerts() {
        if (!alertsLoaded) {
            console.log('loading alerts from db...')
            let { data: alerts } = await axios.get(`${config.server}messagery/alerts`);
            addAlerts(alerts);

            markAlertsAsLoaded();
        }
    }

    return { isLogged, login, logout, user, rehidrating, doing_login, updateUser, alertCounter, retrieveAlerts, alerts };
}