import create from 'zustand';

import { transformCommunityData } from '../utils';

/* Route store */

const useRouteStore = create((set, get) => ({
    communities: [],
    currentCommunity: null,
    setCurrentCommunity: (community) => set({
        currentCommunity: community,
    }),
    currentTool: null,
    setCurrentTool: (tool) => set({
        currentTool: tool,
    }),
    params: null,
    setParams: (params) => set({
        params,
    }),
    rounting: false,
    setRouting: (rounting) => set({
        rounting,
    }),
    cacheCommunity: (communities) => { /* or single community */
        if (!Array.isArray(communities)) communities = [communities];

        let nCommunities = get().communities;

        communities.forEach(community => {
            let eCommunity = nCommunities.find(({ id }) => community.id === id)
            if (eCommunity) eCommunity = transformCommunityData(community);
            else {
                nCommunities.push(transformCommunityData(community));
            }
        })

        set({
            communities: nCommunities,
        })
    },
    resetCache: () => set({
        communities: [],
    })
}))

export default useRouteStore;