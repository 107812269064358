import { useContext } from 'react';

import useUserStore from '../stores/useUserStore';
import SocketContext from '../context/SocketContext';

export default function useMessagery() {
    const addRoom = useUserStore(state => state.addRoom)
    const removeRoom = useUserStore(state => state.removeRoom)    
    
    const socket = useContext(SocketContext);

    const onEnter = (room) => {
        socket.emit('enter', room); /* wait callback? */

        addRoom(room);
    }
    const onLeave = (room) => {
        socket.emit('leave', room); /* wait callback? */

        removeRoom(room);
    }
    const onSend = (roomName, notification, callback) => {
        socket.emit('new_message', roomName, notification, callback);
    }

    return { onEnter, onLeave, onSend };
}