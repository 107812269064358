import create from 'zustand';

/* Dorothy store */

const useDorothyStore = create(set => ({
    isMessageryConnected: null,
    setMessageryStatus: (connected) => set({
        isMessageryConnected: connected,
    })
}))

export default useDorothyStore;